<template>
    <div ref="container" class="relative">
        <transition
            :name="transitionName"
            @before-leave="onBeforeLeave"
            @enter="onEnter"
            @after-leave="onAfterLeave"
        >
            <slot />
        </transition>
    </div>
</template>

<script setup lang="ts">
const container = ref<HTMLElement | null>(null);

const props = withDefaults(
    defineProps<{
        direction: 'forward' | 'backward';
    }>(),
    {
        direction: 'forward',
    },
);

const leavingElHeight = ref(0);

const transitionName = computed(() => `page-slide-${props.direction}`);

const onBeforeLeave = (el: HTMLElement) => {
    leavingElHeight.value = el.scrollHeight;
};

const onEnter = (el: HTMLElement) => {
    const maxHeight = Math.max(leavingElHeight.value, el.scrollHeight);

    if (container.value) {
        container.value.style.height = `${maxHeight}px`;
    }
};

const onAfterLeave = () => {
    if (container.value) {
        container.value.style.height = '';
    }
};
</script>
