<template>
    <div class="pb-20 pt-10 md:pb-24 md:pt-20">
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="py-12 text-center">
                <Spinner class="text-6xl" />
            </div>

            <div v-else>
                <BlockContainer
                    background-color="white"
                    bottom-angle="right"
                    angle-background-color="light-blue"
                    slot-class="pb-20 md:pb-32"
                >
                    <div class="flex flex-col gap-12 lg:flex-row">
                        <div class="lg:w-80 2xl:w-96">
                            <ResultsIntro />
                            <ResultsIntroActions
                                class="mt-10 hidden lg:block"
                                @email-results-click="emailResultsModal?.open"
                            />
                        </div>

                        <ResultsProductCardGrid
                            class="flex-1"
                            :cards="results?.productCards || []"
                        />

                        <ResultsIntroActions
                            class="lg:hidden"
                            @email-results-click="emailResultsModal?.open"
                        />

                        <EmailResultsModal
                            ref="emailResultsModal"
                            :results-path="resultsPath"
                        />
                    </div>
                </BlockContainer>

                <BlockContainer
                    background-color="light-blue"
                    bottom-angle="left"
                >
                    <template #container>
                        <div class="py-20">
                            <div class="container">
                                <h1>Your Resources</h1>

                                <div class="my-12 hidden md:block">
                                    <ResultsResourceCardGrid
                                        :cards="results?.resourceCards || []"
                                    />
                                </div>
                            </div>

                            <ResultsResourceCardSlider
                                class="mt-8 md:hidden"
                                :cards="results?.resourceCards || []"
                            />
                        </div>
                    </template>
                </BlockContainer>

                <BlockContainer
                    background-color="white"
                    slot-class="pt-20 md:pt-32 xl:pt-48 xl:pb-6"
                >
                    <ResultsProductLineHero />
                </BlockContainer>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import ResultsIntro from '~/components/product-finder/components/results-view/ResultsIntro.vue';
import ResultsIntroActions from '~/components/product-finder/components/results-view/ResultsIntroActions.vue';
import { ProductFinderResults } from '~/types/ProductFinder';
import { alert } from '~/utils/alertModals';
import { getResponseErrorsAsHtml } from '~/utils/helpers';
import ResultsProductCardGrid from '~/components/product-finder/components/results-view/ResultsProductCardGrid.vue';
import ResultsResourceCardSlider from '~/components/product-finder/components/results-view/ResultsResourceCardSlider.vue';
import ResultsProductLineHero from '~/components/product-finder/components/results-view/ResultsProductLineHero.vue';
import ResultsResourceCardGrid from '~/components/product-finder/components/results-view/ResultsResourceCardGrid.vue';
import EmailResultsModal from '~/components/product-finder/components/results-view/EmailResultsModal.vue';

const emailResultsModal = ref<InstanceType<typeof EmailResultsModal> | null>(
    null,
);

const props = defineProps<{
    selections: URLSearchParams;
}>();

const loading = ref(true);
const results = ref<ProductFinderResults | null>(null);

const resultsPath = computed(() => {
    return useRoute().path + '#results&' + props.selections.toString();
});

onMounted(() => {
    useAuth()
        .apiRequest(
            'GET',
            'product-finder/results',
            Object.fromEntries(props.selections),
        )
        .then((response: any) => {
            results.value = response.data;
        })
        .catch((error) => {
            alert('Error loading results', getResponseErrorsAsHtml(error.data));
        })
        .finally(() => {
            loading.value = false;
        });
});
</script>
