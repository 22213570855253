<template>
    <div class="container">
        <div class="pb-20 pt-5 md:pb-24 md:pt-8">
            <ProductFinderProgressBar :current-step-index="currentStepIndex" />

            <ProductFinderStepsNavigation
                :current-step-label="currentStep.label"
                :back-hash="backHash"
                :skip-hash="skipHash"
                class="mt-8 md:mt-10"
            />

            <transition name="fade" mode="out-in" :duration="200">
                <div
                    :key="currentStepIndex"
                    class="mt-8 grid gap-4 md:mt-10 md:gap-6"
                >
                    <a
                        v-for="choice in currentStep.choices"
                        :key="choice.key"
                        :href="`${baseHash}&${currentStep.key}=${choice.key}`"
                        class="btn-secondary !flex h-[80px] items-center justify-center text-base ring-grind/20 md:h-[98px] md:text-xl"
                        :data-cy="`${currentStep.key}-${choice.key}`"
                    >
                        <span v-html="sanitizeHtml(choice.label)"></span>
                    </a>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProductFinderStep } from '~/types/ProductFinder';
import ProductFinderProgressBar from '~/components/product-finder/components/ProductFinderProgressBar.vue';
import ProductFinderStepsNavigation from '~/components/product-finder/components/ProductFinderStepsNavigation.vue';

const props = defineProps<{
    steps: ProductFinderStep[];
    selections: URLSearchParams;
}>();

const currentStepIndex = ref(0);

const currentStep = computed(() => {
    return props.steps[currentStepIndex.value];
});

const baseHash = computed(() => {
    if (props.selections.size + 1 === props.steps.length) {
        return `#results&${props.selections.toString()}`;
    } else if (props.selections.size) {
        return `#steps&${props.selections.toString()}`;
    } else {
        return '#steps';
    }
});

const backHash = computed(() => {
    if (0 === currentStepIndex.value) {
        return '#intro';
    } else {
        const params = new URLSearchParams(props.selections.toString());
        params.delete(props.steps[currentStepIndex.value - 1].key);
        return params.size ? `#steps&${params.toString()}` : '#steps';
    }
});

const skipHash = computed(() => {
    return `${baseHash.value}&${currentStep.value.key}=skip`;
});

watch(
    () => props.selections,
    (newSelections) => {
        let newStepIndex = 0;

        [...props.steps].reverse().forEach((step) => {
            if (!newSelections.has(step.key)) {
                newStepIndex = props.steps.indexOf(step);
            }
        });

        currentStepIndex.value = newStepIndex;
    },
    { immediate: true },
);
</script>
