<template>
    <Modal ref="modal">
        <template #title>You are about to leave the Product Finder.</template>

        <p>
            You are about to exit the Product Finder and lose the progress
            you’ve made. Are you sure you want to leave?
        </p>

        <template #actions="{ close }">
            <div class="grid w-full grid-rows-2 gap-2 lg:flex lg:justify-end">
                <button class="btn-secondary" @click="close">
                    No, finish the Product Finder quiz
                </button>

                <NuxtLink class="btn-primary text-base" :to="confirmTo">
                    Yes, exit the Product Finder
                </NuxtLink>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';

const modal = ref<InstanceType<typeof Modal> | null>(null);

const confirmTo = ref('');

const open = (returnUrl: string) => {
    confirmTo.value = returnUrl;
    modal.value?.open();
};

defineExpose({
    open,
});
</script>
