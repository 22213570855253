<template>
    <ProductFinderHeader :hash="hash" :return-url="returnUrl" />

    <transition name="fade" mode="out-in">
        <div v-if="loading" class="py-12 text-center">
            <Spinner class="text-6xl" data-cy="product-finder-initializing" />
        </div>

        <ProductFinderView
            v-else
            v-editable="block"
            :hash="hash"
            :steps="steps"
        />
    </transition>
</template>

<script setup lang="ts">
import ProductFinderHeader from '~/components/product-finder/components/ProductFinderHeader.vue';
import ProductFinderView from '~/components/product-finder/ProductFinderView.vue';
import { ProductFinderStep } from '~/types/ProductFinder';
import { scrollToTop } from '~/utils/helpers';
import { alert } from '~/utils/alertModals';

defineProps<{
    block: any;
    nextBlock: any;
}>();

const { previousRoute } = usePreviousRoute();

const hash = computed(() => {
    return useRoute().hash || '';
});

const state = reactive<{
    loading: boolean;
    returnUrl: string;
    steps: ProductFinderStep[];
}>({
    loading: true,
    returnUrl: '/',
    steps: [],
});

watch(hash, () => {
    scrollToTop();
});

onMounted(() => {
    if (previousRoute.value) {
        state.returnUrl = previousRoute.value.path;
    }

    useAuth()
        .apiRequest('GET', 'product-finder/init')
        .then((response: any) => {
            state.steps = response.data.steps;
        })
        .catch((error) => {
            alert(
                'Error loading product finder',
                getResponseErrorsAsHtml(error.data),
            );
        })
        .finally(() => {
            state.loading = false;
        });
});

const { loading, returnUrl, steps } = toRefs(state);
</script>
