<template>
    <div>
        <div
            class="transition-all duration-500"
            :style="`margin-left: ${percentageComplete}%;`"
        >
            <div class="relative -ml-12 h-20 w-16 md:-ml-24 md:h-28 md:w-24">
                <transition name="fade" :duration="500">
                    <img
                        v-if="2 === currentStepIndex"
                        src="~/assets/images/product-finder/sterling-progress-3.svg"
                        alt="Sterling progress 3"
                        class="absolute h-full w-full object-contain object-bottom md:left-1"
                    />

                    <img
                        v-else-if="1 === currentStepIndex"
                        src="~/assets/images/product-finder/sterling-progress-2.svg"
                        alt="Sterling progress 2"
                        class="absolute h-full w-full object-contain object-bottom md:left-1"
                    />

                    <img
                        v-else
                        src="~/assets/images/product-finder/sterling-progress-1.svg"
                        alt="Sterling progress 1"
                        class="absolute h-full w-full object-contain object-bottom md:left-1"
                    />
                </transition>
            </div>
        </div>

        <div class="mt-[3px] h-[7px] rounded-full bg-grind-200 md:mt-[5px]">
            <div
                class="h-full rounded-full bg-brew transition-all duration-500"
                :style="`width: ${percentageComplete}%;`"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    currentStepIndex: number;
}>();

const percentageComplete = computed(() => {
    return (props.currentStepIndex + 1) * 25;
});
</script>
