<template>
    <div>
        <h1>Your perfect package awaits!</h1>

        <div class="mt-8 flex flex-row items-center gap-6 lg:flex-col lg:gap-8">
            <p class="order-2 text-base lg:order-1">
                Congratulations, you made it! Here are our top product
                recommendations based on your answers. The hard part’s done, now
                you’re just on to ordering! We’re here to help guide you on each
                step of your packaging journey.
            </p>

            <div
                class="relative order-1 flex w-24 flex-shrink-0 justify-center lg:order-2 lg:w-full lg:pt-20"
            >
                <img
                    src="~/assets/images/product-finder/you-made-it.svg"
                    alt="You made it!"
                    class="absolute -left-10 top-0 hidden lg:inline-block lg:max-[1088px]:-left-4 xl:max-[1344px]:-left-4 2xl:max-[1600px]:-left-4"
                />
                <img
                    src="~/assets/images/product-finder/sterling-results.svg"
                    alt="Sterling star success"
                    class="w-full lg:w-56"
                />
            </div>
        </div>
    </div>
</template>
