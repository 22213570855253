<template>
    <div
        class="group relative flex flex-col justify-between gap-5 rounded-lg bg-white p-6 shadow-card-lg transition hover:bg-brew"
        data-cy="product-finder-resource-card"
    >
        <a :href="card.link.url" target="_blank" class="absolute inset-0"></a>

        <div>
            <div class="mb-6 group-hover:text-white">
                <span class="h-12 w-12 transition-colors" v-html="icon" />
            </div>

            <div
                class="text-sm font-semibold uppercase text-drip transition group-hover:text-white"
            >
                {{ card.subtitle }}
            </div>

            <h3 class="mt-2 transition group-hover:text-white">
                {{ card.title }}
            </h3>

            <p class="mt-6 text-base transition group-hover:text-white">
                {{ card.content }}
            </p>
        </div>

        <div>
            <span class="text-brew transition group-hover:text-white">
                {{ card.link.label }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProductFinderResourceCard } from '~/types/ProductFinder';

const iconsImport = import.meta.glob(
    '/assets/images/product-finder/resource-card-icons/**/**.svg',
    { as: 'raw', eager: false },
);

const props = defineProps<{
    card: ProductFinderResourceCard;
}>();

const icon =
    await iconsImport[
        `/assets/images/product-finder/resource-card-icons/${props.card.icon}.svg`
    ]();
</script>
