<template>
    <div
        class="transition-opacity duration-1000"
        :class="{ 'opacity-0': !carouselLoaded }"
    >
        <Swiper
            :centered-slides="true"
            :loop="true"
            :modules="[Pagination]"
            :pagination="true"
            slides-per-view="auto"
            @swiper="onSwiperInit"
        >
            <SwiperSlide
                v-for="card in cards"
                :key="card.title"
                class="swiper-slide flex h-auto w-3/4 px-3.5 py-2 sm:w-4/5"
            >
                <ResultsResourceCard :card="card" />
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script setup lang="ts">
import { ProductFinderResourceCard } from '~/types/ProductFinder';
import ResultsResourceCard from '~/components/product-finder/components/results-view/ResultsResourceCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';

defineProps<{
    cards: ProductFinderResourceCard[];
}>();

const carouselLoaded = ref(false);

const onSwiperInit = () => {
    carouselLoaded.value = true;
};
</script>
