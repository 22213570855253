<template>
    <Modal ref="modal" :allow-close="!submitting" :show-action-buttons="false">
        <template #title>Email your recommendations</template>

        <p>
            We’ve stored your results. Send your recommendations and pick them
            back up anytime.
        </p>

        <Alert v-if="successMessage" type="success" class="mt-8">
            {{ successMessage }}
        </Alert>

        <ValidatedForm @submit="onSubmit">
            <TextInput
                v-model="email"
                type="email"
                name="email"
                label="Enter Email Address *"
                container-class="mt-8"
                :submitting="submitting"
                :form-errors="formErrors"
                required
            />

            <LoadingButton
                class="btn-primary mt-8 w-full"
                :loading="submitting"
                type="submit"
            >
                Email my recommendations
            </LoadingButton>
        </ValidatedForm>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';
import ValidatedForm from '~/components/forms/ValidatedForm.vue';
import TextInput from '~/components/forms/TextInput.vue';
import { FormErrors } from '~/types/miscellaneous';

const modal = ref<InstanceType<typeof Modal> | null>(null);

const props = defineProps<{
    resultsPath: string;
}>();

const state = reactive<{
    email: string;
    submitting: boolean;
    formErrors: FormErrors;
    successMessage: string | null;
}>({
    email: '',
    submitting: false,
    formErrors: {},
    successMessage: null,
});

const open = () => {
    modal.value?.open();
};

const onSubmit = () => {
    state.submitting = true;
    state.formErrors = {};
    state.successMessage = null;

    useAuth()
        .apiRequest('POST', 'product-finder/email-results', {
            email: state.email,
            resultsPath: props.resultsPath,
            allProductsPath: '/products',
        })
        .then(() => {
            state.email = '';
            state.successMessage = 'Your results have been sent!';
        })
        .catch((error) => {
            state.formErrors = getFormErrors(error.data);
        })
        .finally(() => {
            state.submitting = false;
        });
};

const { email, submitting, formErrors, successMessage } = toRefs(state);

defineExpose({ open });
</script>
